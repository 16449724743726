import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {theme} from '../constants';
import {Typography} from '@mui/material';
import {fetchCategories} from '../services/categoryService';
import {useMediaQuery, useTheme} from '@mui/material';

type Props = {
  links?: {label: string; path: string}[];
  logo?: boolean;
  copyright?: boolean;
};

export const Footer: React.FC<Props> = ({links, logo, copyright}) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<any[]>([]);
  const [hoveredCategory, setHoveredCategory] = useState<string | null>(null);
  const themeone = useTheme();
  const isMobile = useMediaQuery(themeone.breakpoints.down('sm'));
  useEffect(() => {
    const loadCategories = async () => {
      try {
        const categoriesData = await fetchCategories();
        setCategories(categoriesData);
      } catch (error) {
        console.error('Failed to load categories:', error);
      }
    };

    loadCategories();
  }, []);

  const renderLogo = (): JSX.Element | null => {
    if (logo) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <img
            src='https://mariaandsons.com/img/Icons/m&s_lgo.png' // Replace with your actual image path
            alt='Logo'
            style={{
              width: '190px',
              height: '110px',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/Home')}
          />
          {/* <Typography width={'250px'}>
            Explore, discover, and indulge in the joy of finding those special
            pieces that make you feel truly at home.
          </Typography> */}
        </div>
      );
    }

    return null;
  };

  const renderCategories = (): JSX.Element | null => {
    if (categories.length > 0) {
      return (
        <nav
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            gap: '5px',
            marginTop: '20px',
          }}
        >
          Explore, discover, and indulge in the joy of finding those special
          pieces that make you feel truly at home.
        </nav>
      );
    }

    return null;
  };

  const renderLinks = (): JSX.Element | null => {
    if (links) {
      return (
        <div style={{textAlign: 'left'}}>
          <Typography
            fontWeight={'bold'}
            fontSize={'20px'}
            marginBottom={'10px'}
            textAlign={'center'}
          >
            Contact
          </Typography>
          <Typography textAlign={'center'}>
            {'+251 925568081, info@mariaandsons.com'
              .split(',')
              .map((text, index) => (
                <React.Fragment key={index}>
                  {text.trim()}
                  <br />
                </React.Fragment>
              ))}
          </Typography>
        </div>
      );
    }

    return null;
  };

  const renderCopyright = (): JSX.Element | null => {
    if (copyright) {
      return (
        <div
          style={{
            fontSize: '14px',
            color: theme.colors.black,
            gridColumn: '1 / -1',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          © {new Date().getFullYear()} Maria & Sons, Inc. All rights reserved.
          Design by Avetol Technologies
        </div>
      );
    }

    return null;
  };

  return (
    <footer
      style={{
        padding: '20px',
        backgroundColor: theme.colors.ghostWhite,
        borderTop: `1px solid ${theme.colors.aliceBlue2}`,
        display: isMobile ? 'flex' : 'grid', // Use flex for mobile, grid for larger screens
        flexDirection: isMobile ? 'column' : 'initial', // Column layout on mobile
        gridTemplateColumns: !isMobile ? '1fr 1fr 1fr' : 'none', // Apply grid only on larger screens
        gap: '10px',
        alignItems: isMobile ? 'center' : 'start', // Center items on mobile
        textAlign: isMobile ? 'center' : 'initial', // Center text on mobile
      }}
    >
      {renderLogo()}
      {renderCategories()}
      {renderLinks()}
      {renderCopyright()}
    </footer>
  );
};
